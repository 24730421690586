import * as React from "react"
import { Link } from "gatsby"
import { Parallax } from "react-parallax"

const Banner = ({ title, description, link, image }) => {
  return (
    <section>
      <Parallax bgImage={image.url} strength={500}>
        <article style={{ height: 400 }} className="container">
          <div className="text-center py-5">
            <h3 className="fs-3 text-white text-shadow mt-5">{title}</h3>
            <p className="bg-shadow text-dark fs-5 py-2 w-75 w-md-50 w-lg-25 mx-auto">{description}</p>
            <Link to={link || `/promociones`} className="btn btn-default border-0 rounded-0 shadow-none px-4 py-2" title={title}>
              Ver promociones
            </Link>
          </div>
        </article>
      </Parallax>
    </section>
  )
}

export default Banner;