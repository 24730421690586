import * as React from "react"
import MainLayout from "../components/layouts/main-layout"
import Banner from "../components/ui/banner"
import Block from "../components/ui/block"
import BlockImage from "../components/ui/block-image"
import Carousel from "../components/ui/carousel"

import CategoryBLock from "../components/ui/category-block"
import ProductCard from "../components/ui/product-card"
import useBlocks from "../hooks/use-blocks"
import useOutstanding from "../hooks/use-outstanding"

// markup
const IndexPage = () => {
  const images = useBlocks().getBannerImages('BANNER_PRINCIPAL')
  const services = useBlocks().getBlock('BL_SERVICIOS')
  const work = useBlocks().getBlock('BL_TRABAJO_IDEAL')
  const banner = useBlocks().getPromotion('PROMO_ESTACION_FOIF')
  const products = useOutstanding().getAll()
  return (
    <MainLayout>
      <section className="container-lg p-0 py-lg-5">
        <Carousel images={images} name="Tu equipo - Equipos y Servicios Topográficos" />
      </section>
      <section className="bg-light">
        <article className="container">
          <Block title={services.title} description={services.description} />
        </article>
      </section>
      <CategoryBLock />
      <section className="bg-light">
        <article className="container">
          <BlockImage title={work.title} description={work.description} link={work.link} image={work.image} />
        </article>
      </section>
      <section className="container py-5">
        <h3 className="fs-4 pt-3 pb-2">Productos relacionados</h3>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 mt-2">
          {products?.map(({ id, name, excerpt, slug, image }) => (
            <div className="col" key={id}>
              <ProductCard name={name} excerpt={excerpt} slug={slug} image={image} />
            </div>
          ))}
        </div>
      </section>
      <Banner title={banner.title} description={banner.description} link={banner.link} image={banner.image} />
    </MainLayout>
  )
}

export default IndexPage
