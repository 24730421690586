import * as React from "react"
import { Link } from "gatsby"

const BlockImage = ({ title, description, link, image }) => (
  <div className="row py-5">
    <div className="col-12 col-lg-6 text-center">
      <img className="img-fluid rounded-3" src={image.url} alt={title} />
    </div>
    <div className="col-12 col-lg-6">
      <div className="text-center px-3 py-5">
        <h3 className="fs-4">{title}</h3>
        <p className="p-4 p-sm-2 truncate-text">{description}</p>
        <Link to={link} className="btn btn-default border-0 rounded-0 shadow-none px-4 py-2" title="Conoce más de nosotros">
          Ver más ...
        </Link>
      </div>
    </div>
  </div>
)

export default BlockImage