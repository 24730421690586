import { graphql, useStaticQuery } from "gatsby"

const useOutstanding = () => {
  const { tuequipoJson: { data: { outstanding: products } } } = useStaticQuery(graphql`{
    tuequipoJson {
      data {
        outstanding {
          id
          name
          slug
          excerpt
          image {
            url
          }
        }
      }
    }
  }`)

  return ({
    getAll: () => {
      return products
    },
  })
}

export default useOutstanding